<template>
    <div id="fruiter">
        <CSTabBar :tabbar="orderState" :checkedTab="listQuery.state" @changeTabBar="changePayStateTabBar"/>
        <!-- 点击苹果产生数据时显示的内容 -->
        <template v-if="listQuery.state == 1" class="box_body">
            <div class="filter-panel">
                <CSSelect>
                    <el-date-picker
                            v-model="startTime"
                            type="date"
                            style="width: 140px"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择开始时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <span class="inline-gray" style="margin-left: -10px;"></span>
                <CSSelect>
                    <el-date-picker
                            v-model="endTime"
                            type="date"
                            style="width: 140px"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择结束时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <input class="cs-input" v-model="search" placeholder="搜索姓名/手机号"/>
                <CSSelect>
                    <select
                            v-model="produceSourceList">
                        <option value="">
                            全部产生来源
                        </option>
                        <option
                                v-for="(state, key) in produceSource"
                                :key="key"
                                :value="key"
                                style="color: #000"
                        >
                            {{ state }}
                        </option>
                    </select>
                </CSSelect>
                <CSSelect>
                    <select v-model="produceStateList">
                        <option value="" style="color: #000">
                            状态不限
                        </option>
                        <option
                                v-for="(state, key) in produceState"
                                :key="key"
                                :value="key"
                                style="color: #000"
                        >
                            {{ state }}
                        </option>
                    </select>
                </CSSelect>

                <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="getProduceData()"
                >
                    查询
                </button>
            </div>
            <div class="result-panel">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <div class="table-header-panel">
                    <span
                    >已产生总量:<i
                    >{{
                        totalProduceSourceData.produceCount == null ? 0 : totalProduceSourceData.produceCount
                        }}个</i
                    ></span
                    >
                            <span style="margin-left: 30px"
                            >已摘取总量:<i
                            >{{
                                totalProduceSourceData.alreadyExtractCount == null
                                        ? 0
                                        : totalProduceSourceData.alreadyExtractCount
                                }}个</i
                            ></span
                            >
                            <span style="margin-left: 30px"
                            >未摘取总量:<i
                            >{{
                                totalProduceSourceData.notExtractCount == null
                                        ? 0
                                        : totalProduceSourceData.notExtractCount
                                }}个</i
                            ></span
                            >
                            <span style="margin-left: 30px"
                            >坠落总量:<i
                            >{{
                                totalProduceSourceData.invalidationCount == null
                                        ? 0
                                        : totalProduceSourceData.invalidationCount
                                }}个</i
                            ></span
                            >
                            <span style="margin-left: 30px"
                            >过期总量:<i
                            >{{
                                totalProduceSourceData.expireCount == null
                                        ? 0
                                        : totalProduceSourceData.expireCount
                                }}个</i
                            ></span
                            >
                        </div>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">产生时间</th>
                            <th class="text-center">产生来源</th>
                            <th class="text-center">产生数量</th>
                            <th class="text-center">姓名</th>
                            <th class="text-center">手机号</th>
                            <th class="text-center">所属企业</th>
                            <th class="text-center">状态</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <template
                                v-for="(log, index1) in produceSourceData.data"
                        >
                            <tr :key="index1">
                                <td class="text-center date-td">
                                    {{ log.createTime }}
                                </td>
                                <td class="text-center">
                                    {{ log.action }}
                                </td>
                                <td class="text-center">
                                    {{ log.amount }}个
                                </td>
                                <td class="text-center">
                                    {{ log.userName }}
                                </td>
                                <td class="text-center">
                                    {{ log.userPhone }}
                                </td>
                                <td class="text-center">
                                    {{ log.userCompany ||`-` }}
                                </td>
                                <td class="text-center">
                                    {{ produceState[log.state] }}
                                </td>
                            </tr>
                        </template>
                    </template>
                </CSTable>


                <Pagination/>
            </div>
        </template>
        <!-- 点击苹果红包数据时显示的内容 -->
        <template v-if="listQuery.state == 2" class="box_body">
            <div class="filter-panel">
                <CSSelect>
                    <el-date-picker
                            v-model="startTime"
                            type="date"
                            style="width: 140px"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择开始时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <span class="inline-gray" style="margin-left: -10px;"></span>
                <CSSelect>
                    <el-date-picker
                            v-model="endTime"
                            type="date"
                            style="width: 140px"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择结束时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <input class="cs-input" v-model="search" placeholder="搜索苹果红包名称/备注"/>
                <CSSelect>
                    <select v-model="issueStateList">
                        <option value="" style="color: #000">
                            状态不限
                        </option>
                        <option
                                v-for="(state, key) in issueState"
                                :key="key"
                                :value="key"
                                style="color: #000"
                        >
                            {{ state }}
                        </option>
                    </select>
                </CSSelect>

                <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="getRedPacketData()"
                >
                    查询
                </button>
            </div>

            <div class="result-panel">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <div class="table-header-panel">
                    <span
                    >发放苹果总量:<i>{{ redPacketDataList.issueTotal }}个</i></span
                    >
                            <span style="margin-left: 30px"
                            >被领取总量:<i>{{ redPacketDataList.receiveTotal }}个</i></span
                            >
                            <span style="margin-left: 30px"
                            >未领取总量:<i>{{ redPacketDataList.notReceiveTotal }}个</i></span
                            >
                            <span style="margin-left: 30px"
                            >可抢人数:<i>{{ redPacketDataList.canGrabNumber }}个</i></span
                            >
                            <span style="margin-left: 30px"
                            >已抢人数:<i>{{ redPacketDataList.alreadyGrabNumber }}个</i></span
                            >
                        </div>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center">发放时间</th>
                            <th class="text-center">苹果红包名称</th>
                            <th class="text-center">状态</th>
                            <th class="text-center">苹果数量</th>
                            <th class="text-center">可抢人群</th>
                            <th class="text-center">可抢人数</th>
                            <th class="text-center">分配方式</th>
                            <th class="text-center">已抢人数</th>
                            <th class="text-center">被领取数量</th>
                            <th class="text-center">未领取数量</th>
                            <th class="text-center">详情</th>
                            <th class="text-center">备注</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <template
                                v-for="(item, index1) in redPacketDataList.datas"
                        >
                            <tr :key="index1">
                                <td class="text-center date-td">
                                    {{ item.createTime }}
                                </td>
                                <td class="text-center">{{ item.name }}</td>
                                <td class="text-center">
                                    {{ redPacketActivityState[item.state] }}
                                </td>
                                <td class="text-center">
                                    {{ item.amount | getNotNullData("个") }}
                                </td>
                                <td class="text-center">
                                    {{item.type == 1?'所有人':item.type == 2?'专属：'+item.userInfo || '-':'-'}}
                                </td>
                                <td class="text-center">
                                    {{ item.shares | getNotNullData("人") }}
                                </td>
                                <td class="text-center">
                                    {{item.manner == 1?'随机':item.manner == 2?'平均':'-'}}
                                </td>
                                <td class="text-center">
                                    {{
                                    item.grabbedShares
                                    | getNotNullData("人")
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                    item.grabbedAmount
                                    | getNotNullData("个")
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                    (item.amount - item.grabbedAmount)
                                    | getNotNullData("个")
                                    }}
                                </td>
                                <td class="text-center">
                                        <span
                                                class="allow-click"
                                                @click="
                                                getRedPacketDetailsData(
                                                    item.id
                                                );
                                            "
                                        >查看</span
                                        >
                                </td>
                                <td class="text-center">
                                    <span v-if="item.comment" @click="lookComment(item.comment)" class="allow-click">
                                        查看
                                    </span>
                                    <template v-else>-</template>
                                </td>
                            </tr>
                        </template>
                    </template>
                </CSTable>
                <Pagination/>
            </div>
        </template>
        <!-- 点击苹果卖出数据时显示的内容 -->
        <template v-if="listQuery.state == 3" class="box_body">
            <div class="filter-panel">
                <CSSelect>
                    <el-date-picker
                            v-model="startTime"
                            type="date"
                            style="width: 140px"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择开始时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <span class="inline-gray" style="margin-left: -10px;"></span>
                <CSSelect>
                    <el-date-picker
                            v-model="endTime"
                            type="date"
                            style="width: 140px"
                            value-format="yyyy-MM-dd"
                            placeholder="请选择结束时间"
                            prefix-icon="el-icon-time"
                            :editable="false"
                    ></el-date-picker>
                </CSSelect>
                <input class="cs-input" v-model="search" placeholder="请输入姓名/手机号码"/>
                <CSSelect>
                    <select
                            v-model="saleMoneyList"
                    >
                        <option value="">
                            全部卖出金额
                        </option>
                        <option
                                v-for="(state, key) in saleMoney"
                                :key="key"
                                :value="key"
                        >
                            {{ state }}
                        </option>
                    </select>
                </CSSelect>
                <CSSelect>
                    <select v-model="saleStateList">
                        <option value="">状态不限</option>
                        <option
                                v-for="(state, key) in saleState"
                                :key="key"
                                :value="key"
                        >
                            {{ state }}
                        </option>
                    </select>
                </CSSelect>
                <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="getSellData()"
                >
                    查询
                </button>
            </div>

            <div class="result-panel">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:header>
                        <div class="table-header-panel">
                    <span
                    >已卖出苹果: <i>{{ sellDataList.sellTotal }}个</i></span
                    >
                            <span style="margin-left: 30px"
                            >卖出用户: <i>{{ sellDataList.sellUserTotal }}人</i></span
                            >
                            <span style="margin-left: 30px"
                            >卖出金额:<i>{{ sellDataList.sellMoney / 100 || '' }}元</i></span
                            >
                        </div>
                    </template>
                    <template v-slot:thead>
                        <tr>
                            <th class="text-center date-td">卖出时间</th>
                            <th class="text-center">姓名</th>
                            <th class="text-center">手机号</th>
                            <th class="text-center">所属企业</th>
                            <th class="text-center">卖出数量</th>
                            <th class="text-center">卖出金额</th>
                            <th class="text-center">苹果来源</th>
                            <th class="text-center">状态</th>
                            <th class="text-center">操作</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <template v-for="(item, index2) in sellDataList">
                            <tr :key="index2">
                                <td class="text-center date-td">
                                    {{ item.createTime }}
                                </td>
                                <td class="text-center">
                                    {{ item.userName }}
                                </td>
                                <td class="text-center">
                                    {{ item.userPhone }}
                                </td>
                                <td class="text-center">
                                    {{ item.userCompany || `-`}}
                                </td>
                                <td class="text-center">
                                    {{ item.pointsAmount }}个
                                </td>
                                <td class="text-center">
                                    ￥{{ item.moneyAmount / 100 }}
                                </td>
                                <td class="text-center">
                                        <span
                                                class="allow-click"
                                                @click="
                                                getSellSourceData(item.id);
                                            "
                                        >查看</span
                                        >
                                </td>
                                <td class="text-center">
                                    {{ sellState[item.auditState] }}
                                </td>

                                <td class="text-center">
                                    <div
                                            class="btn-group"
                                            v-if="item.auditState != 1 && (item.manualReviewMount  <= item.moneyAmount || item.isManualGrant == 0)"
                                    >
                                        <button
                                                type="button"
                                                class="btn btn-primary dropdown-toggle"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                        >
                                            操作
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li v-if="item.auditState === 0">
                                                <a
                                                        @click="issueExchange(item.id, item.userId)"
                                                        style="width: 100%;"
                                                >
                                                    发放
                                                </a>
                                            </li>
                                            <li v-if="item.auditState !== 2">
                                                <a
                                                        @click="blockExchange(item.userId,true)"
                                                        style="width: 100%;"
                                                >
                                                    冻结
                                                </a>
                                            </li>
                                            <li v-if="item.auditState === 2">
                                                <a
                                                        @click="blockExchange(item.userId,false)"
                                                        style="width: 100%;"
                                                >
                                                    解冻
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else>-</div>
                                </td>
                            </tr>
                        </template>
                    </template>
                </CSTable>
                <Pagination/>
            </div>
        </template>
        <!-- 苹果详情弹出框 -->
        <CSDialog
                :dialogVisible="statisticsParticulars"
                dialogTitle="详情"
                dialogWidth="1240px"
                dialogCancelBtnText="关闭"
                :dialogShowConfirmBtn="false"
                @onClose="statisticsParticulars = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px;"
            >
                <table
                        class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
                >
                    <thead>
                    <tr>
                        <th class="text-center">领取时间</th>
                        <th class="text-center">苹果数量</th>
                        <th class="text-center">姓名</th>
                        <th class="text-center">手机号</th>
                        <th class="text-center">所属企业</th>
                    </tr>
                    </thead>
                    <tbody class="box_size" v-if="redPacketDetailsDataList != null">
                    <tr
                            v-for="(item, index) in redPacketDetailsDataList"
                            :key="index"
                    >
                        <td class="text-center date-td">{{ item.grabTime }}</td>
                        <td class="text-center">{{ item.amount }}</td>
                        <td class="text-center">{{ item.userName }}</td>
                        <td class="text-center">{{ item.userPhone }}</td>
                        <td class="text-center">{{ item.userCompany }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </CSDialog>
        <!-- 苹果来源弹出框 -->
        <CSDialog
                :dialogVisible="appleSource"
                dialogTitle="苹果来源"
                dialogWidth="1240px"
                dialogCancelBtnText="关闭"
                :dialogShowConfirmBtn="false"
                @onClose="statisticsParticulars = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px;"
            >
                <table
                        class="footable table table-stripped toggle-arrow-tiny modal-inner-table"
                >
                    <thead>
                    <tr>
                        <th class="text-center">获得时间</th>
                        <th class="text-center">卖出数量</th>
                        <th class="text-center">苹果来源</th>
                    </tr>
                    </thead>
                    <tbody class="box_size" v-if="sellSourceDataList != null">
                    <tr
                            v-for="(item, index) in sellSourceDataList"
                            :key="index"
                    >
                        <td class="text-center date-td">{{ item.acquireTime }}</td>
                        <td class="text-center">{{ item.amount }}</td>
                        <td class="text-center">{{ item.action }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </CSDialog>
        <!-- 二次提示是否冻结的弹出框 -->
        <CSDialog
                :dialogVisible="freeze.visible"
                dialogWidth="460px"
                :dialogTitle="freeze.isBlocked ? '请输入冻结原因' : '请输入解冻原因'"
                @onCancel="freeze.visible = false"
                @onClose="freeze.visible = false"
                @onConfirm="freeze.onOk"
        >
            <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px; text-align: center;">
                <input
                        v-model="freeze.reason"
                        placeholder="请输入内容"
                        style="height: 40px; border: 1px solid #999; width: 400px; border-radius: 4px; padding-left: 10px;"
                />
            </div>
        </CSDialog>
        <ViewModal/>

    </div>
</template>

<script>
    import {
        querySocialServiceAppleProduceDataUrl,
        querySocialServiceAppleRedPacketDataUrl,
        querySocialServiceAppleRedPacketTitleDataUrl,
        querySocialServiceAppleRedPacketDetailsDataUrl,
        querySocialServiceAppleSellDataUrl,
        querySocialServiceSellSourceDataUrl,
        querySocialServiceSellTitleDataUrl,
        socialServiceBlockExchangeUrl,
        socialIssueExchangeUrl,
    } from "@/requestUrl";

    import dayjs from "dayjs";
    import ViewModal from "@/components/ViewModal";
    import Pagination from "@/components/Pagination.vue";
    import CSTabBar from "@/components/common/CSTabBar";
    import CSDialog from "@/components/common/CSDialog";
    import CSSelect from "@/components/common/CSSelect";
    import CSTable from "@/components/common/CSTable";

    export default {
        name: "fruiter",
        components: {
            CSTable,
            CSSelect,
            ViewModal,
            Pagination,
            CSTabBar,
            CSDialog,
        },
        data() {
            return {
                filterHeight: 0,
                statisticsParticulars: false, //控制查看红包统计详情弹出框的显示与隐藏
                appleSource: false, //控制查看苹果来源详情弹出框的显示与隐藏
                grantApple: {
                    visible: false,
                    id: null,
                    onOk: () => {
                    },
                }, //控制发放弹出框的显示与隐藏
                freeze: {
                    visible: false,
                    reason: null,
                    userId: null,
                    isBlocked: null,
                    onOk: () => {
                    },
                }, //控制冻结弹出框的显示与隐藏

                listQuery: {
                    state: 1,
                    userInfo: "",
                    handler: "",
                },
                // 导航菜单
                orderState: {
                    1: "苹果产生数据",
                    2: "苹果红包数据",
                    3: "苹果兑换数据",
                },
                //产生来源
                produceSource: {
                    1: "闪购商城订单",
                    2: "企业服务订单",
                    3: "上门服务订单",
                    4: "会议室订单",
                    5: "用户评价反馈",
                    6: "每日首次发帖",
                    7: "每日首次回贴",
                    8: "每日签到",
                    9: "提交问卷",
                    10: "抢苹果红包",
                },
                //   苹果产生数据状态
                produceState: {
                    1: "未成熟",
                    2: "成熟",
                    3: "已摘",
                    4: "坠落",
                    5: "已兑换",
                    6: "已过期",
                },
                //   发放状态
                issueState: {
                    2: "发放中",
                    3: "已结束",
                },
                //   卖出状态
                saleState: {
                    0: "未发放",
                    1: "已发放",
                },
                //  选择卖出金额
                saleMoney: {
                    1: "0-10元",
                    2: "10-50元",
                    3: "50-100元",
                    4: "100元以上",
                },
                //  返回卖出状态
                sellState: {
                    0: "未发放",
                    1: "已发放",
                    2: "已冻结",
                },
                redPacketActivityState: {
                    1: "已创建",
                    2: "发放中",
                    3: "已停止",
                },
                saleStateList: "", //存放选择的卖出状态
                issueStateList: "", //存放选择的红包状态
                produceSourceList: "", //存放产生来源的
                produceStateList: "", //存放选择的产生数据所需状态
                depositState: "", //存放选择中的时间状态
                saleMoneyList: "", //存放选择的卖出金额
                isBlocked: true, //存放积分是否冻结
                reason: "", //冻结理由
                type: "",
                //   开始时间
                startTime: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
                endTime: dayjs().format("YYYY-MM-DD"), //结束时间   必填
                search: "", //用户信息，消费码，商户信息
                pageNo: 1,
                pageSize: 10,
                produceSourceData: {}, //苹果产生数据
                totalProduceSourceData: {}, //苹果产生数据
                redPacketDataList: [], //苹果红包数据
                amount: 0, //红包苹果总数量
                grabbedAmount: 0, //已抢总数量
                shares: 0, //可抢总人数
                grabbedShares: 0, //已抢总人数
                redPacketDetailsDataList: [], //苹果红包详情数据
                sellDataList: [], //苹果卖出数据
                sellSourceDataList: [], //苹果卖出中苹果来源数据
                sellApple: 0, //已卖出苹果
                sellUser: 0, //卖出用户
                sellMoney: 0, //卖出金额
            };
        },
        filters: {
            // 返回不为空的数据
            getNotNullData(val, unit) {
                return val === null ? '-' : val + unit;
            },
        },
        destroyed() {
            window.removeEventListener("keydown", this.getDataDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.getDataDown);
        },
        mounted() {
            this.getProduceData();
            window.addEventListener("keydown", this.getDataDown);
            this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
                const tabBarHanlder = {
                    1: this.getProduceData,
                    2: this.getRedPacketData,
                    3: this.getSellData,
                };
                tabBarHanlder[this.listQuery.state](_currentPage);
            });
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            window.addEventListener('resize', () => {
                this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            })
        },
        updated() {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        },
        methods: {
            getDataDown(e) {
                if (e.keyCode != 13) {
                    return
                }
                const refreshHandler = {
                    1: this.getProduceData,
                    2: this.getRedPacketData,
                    3: this.getSellData,
                }
                refreshHandler[this.listQuery.state]();
            },
            lookComment(comment) {
                this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                    title: '备注',
                    content: comment
                });
            },
            changePayStateTabBar(state) {
                if (this.listQuery.state != state) {
                    this.listQuery.state = state;
                    const tabBarHanlder = {
                        1: this.getProduceData,
                        2: this.getRedPacketData,
                        3: this.getSellData,
                    };

                    if (state == 3) {
                        this.startTime = dayjs()
                            .subtract(1, "day")
                            .format("YYYY-MM-DD");
                        this.endTime = dayjs().format("YYYY-MM-DD");
                    } else {
                        this.endTime = dayjs().format("YYYY-MM-DD");
                        this.startTime = dayjs()
                            .subtract(30, "day")
                            .format("YYYY-MM-DD");
                    }
                    this.search = "";
                    tabBarHanlder[state]();
                }
                this.hidden = false;
            },
            // 获取苹果产生的数据
            getProduceData(pageNo = 1, pageSize = 10) {
                const {startTime, endTime, search} = this;
                const actionType =
                    this.produceSourceList - 0 == 0
                        ? null
                        : this.produceSourceList - 0;
                const state =
                    this.produceStateList - 0 == 0
                        ? null
                        : this.produceStateList - 0;
                this.$fly
                    .post(querySocialServiceAppleProduceDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        actionType,
                        startTime,
                        endTime,
                        search,
                        state,
                        pageNo,
                        pageSize,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                pageSize: pageSize,
                                currentPage: pageNo,
                            })

                          this.totalProduceSourceData=res.data
                        }
                        this.produceSourceData = res.data;
                    });
            },
            // 获取苹果红包数据
            getRedPacketData(pageNo = 1, pageSize = 10) {
                const {search: keyword} = this;
                const timeStart = this.startTime + " 00:00:00";
                const timeEnd = this.endTime + " 23:59:59";
                const state =
                    this.issueStateList - 0 == 0 ? null : this.issueStateList - 0;

                this.$fly
                    .post(querySocialServiceAppleRedPacketDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        timeStart,
                        timeEnd,
                        keyword,
                        state,
                        pageNo,
                        pageSize,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                currentPage: pageNo,
                                pageSize,
                            })
                        }
                        this.redPacketDataList = res.data;
                    });
            },
            // 获取苹果红包标题数据
            getRedPacketTitleData() {
                const timeStart = this.startTime + " 00:00";
                const timeEnd = this.endTime + " 59:59";
                const state =
                    this.issueStateList - 0 == 0 ? null : this.issueStateList - 0;
                const keyword = this.search;
                this.$fly
                    .post(querySocialServiceAppleRedPacketTitleDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        timeStart,
                        timeEnd,
                        keyword,
                        state,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }

                        this.amount = res.data.amount;
                        this.grabbedAmount = res.data.grabbedAmount;
                        this.shares = res.data.shares;
                        this.grabbedShares = res.data.grabbedShares;
                    });
            },
            // 获取苹果红包详情数据
            getRedPacketDetailsData(redPackId) {
                this.$router.push({name: "AppleRedEnvelopeData", query: {redPackId: redPackId}});
            },
            // 获取苹果卖出数据
            getSellData(pageNo = 1, pageSize = 10) {
                const {search: keyword} = this;
                const startTime = this.startTime + " 00:00:00";
                const endTime = this.endTime + " 23:59:00";
                const moneyRange = this.saleMoneyList - 0;
                const state = this.saleStateList - 0 || 3;
                this.$fly
                    .post(querySocialServiceAppleSellDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        startTime,
                        endTime,
                        keyword,
                        moneyRange,
                        state,
                        pageNo,
                        pageSize,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                currentPage: pageNo,
                                pageSize,
                            })
                        }
                        this.sellDataList = res.data.datas;
                        this.sellDataList.sellMoney = res.data.sellMoney;
                        this.sellDataList.sellTotal = res.data.sellTotal;
                        this.sellDataList.sellUserTotal = res.data.sellUserTotal;
                    });
            },
            // 获取苹果卖出中的苹果来源数据
            getSellSourceData(id) {
                this.$router.push({name: "SourceOfApple", params: {id}});
            },
            // 获取苹果卖出标题数据
            getSellTitleData() {
                const state = this.saleStateList - 0 || 3;
                const moneyRange = this.saleMoneyList - 0;
                const keyword = this.search;
                const startTime = this.startTime + " 00:00";
                const endTime = this.endTime + " 23:59";
                this.$fly
                    .post(querySocialServiceSellTitleDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        startTime,
                        endTime,
                        keyword,
                        moneyRange,
                        state,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.sellApple = res.data.pointsAmount;
                        this.sellUser = res.data.userCount;
                        this.sellMoney = res.data.moneyAmount;
                    });
            },
            //冻结/解冻积分
            blockExchange(userId, isBlocked) {
                this.freeze.visible = true;
                this.freeze.isBlocked = isBlocked;
                this.freeze.onOk = () => {
                    const reason = this.freeze.reason;
                    this.$fly
                        .post(socialServiceBlockExchangeUrl, {
                            regionCode: this.$vc.getCurrentRegion().code,
                            userId,
                            reason,
                            isBlocked,
                        })
                        .then((res) => {
                            if (res.code != 0) {
                                return;
                            }
                            this.freeze.visible = false;
                            this.freeze.reason = "";
                            this.getSellData();
                        });
                };
            },
            // 发放积分
            issueExchange(exchangeId, userId) {
                this.grantApple.exchangeId = exchangeId;
                this.$CSDialog.confirm({
                    title: '提示',
                    message: '确定发放吗?',
                    onConfirm: () => {
                        const exchangeId = this.grantApple.exchangeId;
                        console.log(exchangeId);
                        this.$fly
                            .post(socialIssueExchangeUrl, {
                                regionCode: this.$vc.getCurrentRegion().code,
                                exchangeId,
                                userId,
                            })
                            .then((res) => {
                                if (res.code != 0) {
                                    return;
                                }
                                this.$CSDialog.instance.closeDialog();
                                if (res.data.resultCode && res.data.resultCode !== 'SUCCESS') {
                                    this.$CSDialog.alert({
                                        title: '提示',
                                        message: res.data.errCodeDes || res.data.returnMsg
                                    })
                                }
                                this.getSellData();
                            });
                    },
                })
            },
        },
    };
</script>
<style scoped>
    .result-panel-header span {
        margin-right: 20px;
    }

    #fruiter .el-dialog__header {
        padding: 0 !important;
    }

    #fruiter .el-dialog div {
        margin-bottom: 40px;
    }

    #fruiter .el-dialog__body {
        padding: 30px 30px 1px 30px !important;
        margin-bottom: 0px;
        padding-bottom: 0px;
        color: #000;
    }

    #fruiter .el-dialog__footer {
        border-top: 1px solid #999;
        text-align: center !important;
        padding-top: 20px;
    }

    #fruiter .el-dialog div {
        margin-bottom: 20px;
    }

    .refundReason_ .el-dialog__header {
        padding: 0 !important;
    }

    .refundReason_ .el-dialog div {
        margin-bottom: 40px;
    }

    .refundReason_ .el-dialog__body {
        padding: 10px 20 5px 20px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        padding-top: 20px;
        color: #000;
    }

    .refundReason_ .el-dialog__footer {
        border-top: 1px solid #999;
        text-align: center !important;
        padding-top: 20px;
    }

    .refundReason_ .el-dialog div {
        margin-bottom: 20px;
    }

    .operation .dropdown-menu {
        transform: translate3d(-40px, 35px, 0px) !important;
        z-index: 9999 !important;
    }

    .box_body1 .el-card__body {
        padding: 20px 0 0px 0;
    }
</style>
<style scoped>
    select {
        color: #000;
    }

    i {
        font-style: normal;
    }

    a {
        text-decoration: underline;
    }

    .col-lg-12 > .btn:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .col-lg-12 > .btn:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .col-lg-12 > .btn:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .quantityStatistics {
        height: 40px;
        border-bottom: 1px solid #999;
    }

    .text-center {
        padding: 15px 8px 15px 8px;
    }

    .quantityStatistics span {
        font-size: 14px;
        padding: 15px 5px 15px 30px;
    }

    .cs-select .el-date-editor {
        width: 131px;
    }

    .form-control {
        width: 200px;
        height: 30px;
    }

    .cancel_ {
        width: 80px;
        height: 40px;
        background: #ff9f00;
        box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
        border-radius: 6px;
        color: #fff;
        font-size: 24px;
        border: none;
    }

    .determine {
        width: 80px;
        height: 40px;
        background: #1ab394;
        box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
        border-radius: 6px;
        color: #fff;
        border: none;
        font-size: 24px;
        margin-left: 60px;
    }

    #fruiter .el-dialog .SecondaryConfirmation {
        font-size: 24px;
        text-align: center;
    }

    input::-webkit-input-placeholder {
        color: #999;
    }

    input::-moz-placeholder {
        color: #999;
    }

    input::-moz-placeholder {
        color: #999;
    }

    input::-ms-input-placeholder {
        color: #999;
    }
</style>
